import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import axios from 'axios';

const TelegramSignIn = () => {
  const [error, setError] = useState(null);
  const { login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleTelegramAuth = async (initData) => {
      try {
        const response = await axios.post('https://gm-panda-one.vercel.app/api/tg-auth', initData);
        if (response.data && response.data.id) {
          login(response.data);
          navigate('/');
        } else {
          throw new Error('Invalid user data received from server');
        }
      } catch (error) {
        console.error('Telegram WebApp authentication failed:', error);
        setError(error.message || 'Authentication failed. Please try again.');
      }
    };

    if (window.Telegram && window.Telegram.WebApp) {
      const webAppData = window.Telegram.WebApp.initData;
      if (webAppData) {
        const parsedData = Object.fromEntries(new URLSearchParams(webAppData));
        handleTelegramAuth(parsedData);
      } else {
        setError('No authentication data found in Telegram WebApp');
      }
    } else {
      setError('This page is meant to be opened from Telegram.');
    }
  }, [login, navigate]);

  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="card card-dark p-4 shadow-sm" style={{ width: '300px' }}>
          <h2 className="card-title text-center mb-4">Authentication Error</h2>
          <div className="alert alert-danger text-center">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card card-dark p-4 shadow-sm" style={{ width: '300px' }}>
        <h2 className="card-title text-center mb-4">Signing In...</h2>
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TelegramSignIn;