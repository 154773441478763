const LLMSelectionModal = ({ isOpen, onClose, onSelectModel, selectedModel }) => {
  if (!isOpen) return null;

  const handleModelSelect = (model) => {
    onSelectModel(model);
    onClose();
  };

  const supportedProviders = [
    'openai',
    'perplexityai',
    'anthropic',
    'mistral'
  ];

  const models = [
    { name: 'OpenAI', desc: 'Latest GPT model, very powerful', cost: 'Very Cheap', img: '/logo/openai.png', costClass: 'very-cheap', provider: 'openai' },
    { name: 'Perplexity', desc: 'Microsoft\'s search AI', cost: 'Cheap', img: '/logo/perplexityai.png', costClass: 'cheap', provider: 'perplexityai' },
    { name: 'Claude-3', desc: 'Competitive AI model', cost: 'Slightly Expensive', img: '/logo/anthropic.jpg', costClass: 'slightly-expensive', provider: 'anthropic' },
    { name: 'Mistral', desc: 'Competitive AI model', cost: 'Slightly Expensive', img: '/logo/mistralai.png', costClass: 'slightly-expensive', provider: 'mistral' }
  ].filter(model => supportedProviders.includes(model.provider));

  return (
    <div className="modal" style={{ display: isOpen ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.8)' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Choose an AI Model</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <p>Select an AI Model that you would want your panda to respond from.</p>
          <div className="modal-body">
            <div className="list-group">
              {models.map((model, index) => (
                <button
                  key={index}
                  onClick={() => handleModelSelect(model.provider)}
                  className={`list-group-item list-group-item-action d-flex justify-content-between align-items-start ${selectedModel === model.provider ? 'selected' : ''}`}
                >
                  <img src={model.img} alt={model.name} className="me-2 model-image" />
                  <div className="flex-grow-1">
                    <div className="fw-bold">{model.name}</div>
                    <small className="text-muted">{model.desc}</small>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LLMSelectionModal;
