import React from 'react';
import Lottie from 'react-lottie';

function LottieAnimation({ showOrbAnimation, listening, defaultOptions }) {
  return (
    <div className="align-self-center mb-0 pb-0">
      <div className={`lottie-container ${listening ? 'orb-animation-listening' : ''}`} style={{ height: '50vh', width: 'auto', maxWidth: '400px' }}>
        <Lottie options={defaultOptions} isStopped={!showOrbAnimation} />
      </div>
    </div>
  );
}

export default LottieAnimation;
