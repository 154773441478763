import React from 'react';

function Footer() {
  return (
    <footer style={{ textAlign: 'center', padding: '2rem 0', color: '#fff' }}>
      Crafted with <span class="blink"> ❤️</span> & powered by <a href="https://www.capx.fi/" style={{ color: '#fff' }}>Capx</a><br/>
    </footer>
  );
}

export default Footer;
