import React, { useState } from 'react';

function Nav({ onToggleDropdown, dropdownOpen, user, onLogout, credits }) {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleToggle = () => {
    setShowDropdown(!showDropdown);
    onToggleDropdown();
  };

  return (
    <div className="user-nav mx-auto position-relative d-flex align-items-center justify-content-between w-75">
      <div onClick={handleToggle} style={{ cursor: 'pointer' }}>
        <span className="username d-block text-center">
          <img 
            src={user?.photo_url || 'https://via.placeholder.com/40'} 
            alt="Profile" 
            className="rounded-circle mr-2"
            style={{ width: '24px', height: '24px', display: 'inline-block', verticalAlign: 'middle', marginRight: "5px"}}
          />
          Hi, {user?.first_name || 'Guest'} 👋
        </span>
      </div>
      <div className="credits-display">
        {credits !== null && (
          <span className="text-light">Credits: {credits}</span>
        )}
      </div>
      {showDropdown && (
        <div className="dropdown-menu show position-absolute" style={{ top: '100%', right: '0' }}>
          <button className="dropdown-item" onClick={onLogout}>Logout</button>
        </div>
      )}
    </div>
  );
}

export default Nav;