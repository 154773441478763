import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import Home from './Home';
import Login from './Login';
import TelegramSignIn from './TelegramSignIn';
import TelegramEntry from './TelegramEntry';

const RequireAuth = ({ children }) => {
  const { user } = useAuth();
  console.log('RequireAuth - Current user:', user);
  
  if (!user) {
    console.log('User not authenticated, redirecting to login');
    return <Navigate to="/login" />;
  }
  
  console.log('User authenticated, rendering protected route');
  return children;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/tg-signin" element={<TelegramSignIn />} />
          <Route path="/tg-entry" element={<TelegramEntry />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;