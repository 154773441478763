import React, { useState, useEffect } from 'react';

function TranscriptInput({ transcript, interimTranscript, processing, audioPlaying }) {
  const [displayTranscript, setDisplayTranscript] = useState('');
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handle = setTimeout(() => {
      setDisplayTranscript(interimTranscript);
      setVisible(true);
    }, 300);

    return () => clearTimeout(handle);
  }, [interimTranscript]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [transcript, interimTranscript]);

  if (processing || audioPlaying) {
    return null;
  }

  return visible ? (
    <div className="transcript-container mt-0 pb-3 mb-3 pt-0 minus-top">
      <div className="interim-transcript">{displayTranscript}</div>
    </div>
  ) : null;
}

export default TranscriptInput;