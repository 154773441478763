import React, { useState } from 'react';

const PersonaSelectionModal = ({ isOpen, onClose, onSelectPersona, selectedPersona }) => {
  if (!isOpen) return null;

  const handlePersonaSelect = (personaKey) => {
    console.log("Selected persona:", personaKey);
    onSelectPersona(personaKey); // This triggers the passed function from Home
    onClose(); // Closes the modal
  };

  const personas = [
    { name: 'Friendly Panda', desc: 'Warm and engaging, ideal for tutorials', img: '/logo/friendly_panda.webp', key: 'friendly_guide' },
    { name: 'Professional Panda', desc: 'Formal and informative, great for business advice', img: '/logo/professional_panda.webp', key: 'professional_consultant' },
    { name: 'Creative Panda', desc: 'Innovative and imaginative, perfect for brainstorming sessions', img: '/logo/creative_panda.webp', key: 'creative_thinker' },
    { name: 'Technical Panda', desc: 'Deeply knowledgeable, best for technical support', img: '/logo/technical_panda.webp', key: 'technical_expert' }
  ];

  return (
    <div className="modal" style={{ display: isOpen ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.8)' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Choose a Persona</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <p>Select the persona you want to interact with.</p>
          <div className="modal-body">
            <div className="list-group">
              {personas.map((persona, index) => (
                <button
                  key={index}
                  onClick={() => handlePersonaSelect(persona.key)}
                  className={`list-group-item list-group-item-action d-flex justify-content-between align-items-start ${selectedPersona === persona.key ? 'selected' : ''}`}
                >
                  <img src={persona.img} alt={persona.name} className="me-2 persona-image" />
                  <div className="flex-grow-1">
                    <div className="fw-bold">{persona.name}</div>
                    <small className="text-muted">{persona.desc}</small>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonaSelectionModal;
