import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const Login = () => {
  const { user, login } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    console.log('Login component - Current user:', user);
    if (user) {
      console.log('User already logged in, redirecting to home');
      navigate('/');
    }
  }, [user, navigate]);
  useEffect(() => {
    window.onTelegramAuth = async (user) => {
      try {
        console.log('Telegram auth data received:', user);
        const response = await axios.get('https://gm-panda-one.vercel.app/api/auth', { params: user });
        console.log('Backend auth response:', response.data);
        
        if (response.data && response.data.id) {
          login(response.data);
          setIsLoggedIn(true);
          navigate('/');
        } else {
          throw new Error('Invalid user data received from server');
        }
      } catch (error) {
        console.error('Authentication failed:', error);
        setError(error.message || 'Authentication failed. Please try again.');
      }
    };
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?22';
    script.async = true;
    script.setAttribute('data-telegram-login', 'gmpanda_bot');
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-request-access', 'write');
    const container = document.getElementById('telegram-login-container');
    container.appendChild(script);
    return () => {
      if (container) {
        container.removeChild(script);
      }
    };
  }, [login, navigate]);
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card card-dark p-4 shadow-sm" style={{ width: '300px' }}>
        <h2 className="card-title text-center mb-4">Sign in with Telegram</h2>
        <p className="mb-4">Sign in or signup and start chatting with GmPanda now!</p>
        <div id="telegram-login-container" className="text-center mb-3"></div>
        {isLoggedIn && <div className="alert alert-success text-center">User has been logged in successfully!</div>}
        {error && <div className="alert alert-danger text-center">{error}</div>}
      </div>
    </div>
  );
};
export default Login;