import React, { createContext, useState, useEffect } from 'react';
const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const savedUser = localStorage.getItem('telegramUser');
    console.log('Initial load - Retrieved user from localStorage:', savedUser);
    if (savedUser) {
      const parsedUser = JSON.parse(savedUser);
      console.log('Setting user state:', parsedUser);
      setUser(parsedUser);
    }
  }, []);
  const login = (user) => {
    console.log('Logging in user:', user);
    localStorage.setItem('telegramUser', JSON.stringify(user));
    setUser(user);
  };
  const logout = () => {
    console.log('Logging out user');
    localStorage.removeItem('telegramUser');
    setUser(null);
  };
  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  const context = React.useContext(AuthContext);
  console.log('useAuth called, current user:', context.user);
  return context;
};