import React, { useState } from 'react';
import StatusMessage from './StatusMessage';

function Controls({
  recognitionRef,
  onOpenModel,
  onOpenPersonaModal,
  startRecognition,
  stopProcessing,
  modelButtonText,
  personaButtonText,
  isProcessing,
  hasInteracted,
  setHasInteracted,
  setListening,
  setProcessing,
  listening,
  audioPlaying,
  audioSourceRef,
  setAudioPlaying,
  disabled
}) {
  const [statusMessage, setStatusMessage] = useState('');

  const handleStartRecognition = () => {
    if (disabled) {
      // setStatusMessage("You're out of credits. Please purchase more to continue.");
      return;
    }
    if (!recognitionRef.current.isStarted) {
      recognitionRef.current.start();
    }
    setListening(true);
    console.log('Setting hasInteracted to true');
    setHasInteracted(true);
  };

  const handleStopRecognition = () => {
    console.log("Stopping recognition...");
    recognitionRef.current.stop();
    setListening(false);
    setProcessing(false);
    // Stop audio playback if it's playing
    if (audioPlaying && audioSourceRef.current) {
      audioSourceRef.current.stop();
      setAudioPlaying(false);
    }
  };

  return (
    <div className="controls-container d-flex flex-column align-items-center">
      {statusMessage && <StatusMessage message={statusMessage} />}
      <div className="controls-buttons p-3 d-flex align-items-center position-relative">
        <button 
          className={`btn btn-options me-4 ${personaButtonText ? 'active' : ''}`} 
          onClick={onOpenPersonaModal}
          disabled={disabled}
          title="Select Persona"
        >
          <i className="fas fa-user btn-voice-changer"></i>
        </button>
        <button 
          className={`btn btn-model btn-model-animation me-4 ${isProcessing ? 'btn-stop' : ''}`} 
          onClick={isProcessing ? handleStopRecognition : handleStartRecognition}
          disabled={disabled}
        >
          <i className={`fas ${isProcessing ? 'fa-stop' : 'fa-microphone btn-voice-changer'}`}></i>
          <span className="ms-2">{isProcessing ? 'Stop' : 'Speak'}</span>
        </button>
        <button 
          className={`btn btn-options ${modelButtonText ? 'active' : ''}`} 
          onClick={onOpenModel}
          disabled={disabled}
          title="Select Model"
        >
          <i className="fas fa-search btn-voice-changer"></i>
        </button>
      </div>
      {disabled && (
        <div className="out-of-credits-message mt-2 text-danger">
        </div>
      )}
    </div>
  );
}

export default Controls;