import React from 'react';

function StatusMessage({ message }) {
  return (
    <div className="status-message-container d-flex justify-content-center mb-3">
    <div className="status-message">
        <span className="dot"></span> {message}
      </div>
    </div>
  );
}

export default StatusMessage;
