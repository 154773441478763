import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import animationData from './orb.json';
import LLMSelectionModal from './LLMSelectionModal';
import { useAuth } from './AuthContext';
import Nav from './Nav';
import LottieAnimation from './LottieAnimation';
import TranscriptInput from './TranscriptInput';
import StatusMessage from './StatusMessage';
import Footer from './Footer';
import Controls from './Controls';
import PersonaSelectionModal from './PersonaSelectionModal';
import { AudioContext } from 'standardized-audio-context';

function Home() {
  const { user, logout } = useAuth();
  const [credits, setCredits] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showOrbAnimation, setShowOrbAnimation] = useState(false);
  const [isLLMModalOpen, setLLMModalOpen] = useState(true);
  const [isPersonaModalOpen, setPersonaModalOpen] = useState(false);
  const [modelButtonText, setModelButtonText] = useState('');
  const [personaButtonText, setPersonaButtonText] = useState('');
  const [selectedPersona, setSelectedPersona] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [listening, setListening] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [interimTranscript, setInterimTranscript] = useState('');
  const [speechRecognitionSupported, setSpeechRecognitionSupported] = useState(true);
  const recognitionRef = useRef(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const restartTimerRef = useRef(null);
  const debounceTimerRef = useRef(null);
  const lastProcessedTranscript = useRef('');
  const [audioPlaying, setAudioPlaying] = useState(false);
  const audioRef = useRef(null);
  const axiosCancelSource = useRef(axios.CancelToken.source());
  const [hasStarted, setHasStarted] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const audioSourceRef = useRef(null);

  const openPersonaModal = () => setPersonaModalOpen(true);
  const closePersonaModal = () => setPersonaModalOpen(false);
  const isOutOfCredits = credits !== null && credits <= 0;

  const fetchCredits = async () => {
    try {
      const response = await axios.get('https://gm-panda-one.vercel.app/api/fetchcredits', {
        params: { session_token: user.session_token }
      });
      setCredits(response.data.remainingCredits);
    } catch (error) {
      console.error('Error fetching credits:', error);
      setStatusMessage('Error fetching credits');
    }
  };

  useEffect(() => {
    const handleInteraction = () => {
      setHasInteracted(true);
    };

    document.addEventListener('click', handleInteraction);
    document.addEventListener('touchstart', handleInteraction);

    return () => {
      document.removeEventListener('click', handleInteraction);
      document.removeEventListener('touchstart', handleInteraction);
    };
  }, []);

  useEffect(() => {
    if (user && user.session_token) {
      fetchCredits();
    }
  }, [user]);

  useEffect(() => {
    if (isOutOfCredits) {
      // setStatusMessage("You're out of credits. Please purchase more to continue.");
      stopProcessing();
    }
  }, [credits, isOutOfCredits]);

  useEffect(() => {
    if (!audioPlaying && listening) {
      setTranscript('');
    }
  }, [listening, audioPlaying]);

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      setStatusMessage("Speech recognition not supported.");
      setSpeechRecognitionSupported(false);
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = 'en-US';
    recognitionRef.current = recognition;

    recognition.onstart = () => {
      setListening(true);
      setStatusMessage('Listening...');
      recognitionRef.current.isStarted = true;
    };

    recognition.onresult = (event) => {
      let interim = '';
      let finalTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        } else {
          interim += event.results[i][0].transcript;
        }
      }

      setInterimTranscript(interim);
      if (finalTranscript) {
        setTranscript(prev => prev + finalTranscript);
      }
    };

    recognition.onend = () => {
      recognitionRef.current.isStarted = false;
      if (!processing && (transcript || hasInteracted)) {
        setListening(true);
        recognition.start();
        setStatusMessage('Listening...');
      } else {
        setStatusMessage('Tap to speak...');
      }
    };

    recognition.onerror = (event) => {
      console.error("Recognition error:", event.error);
      setListening(false);
      switch (event.error) {
        case 'no-speech':
          setStatusMessage("No speech detected, please try again.");
          break;
        case 'audio-capture':
          setStatusMessage("Microphone not available, please ensure it's connected.");
          break;
        case 'not-allowed':
          setStatusMessage("Permission to use microphone not granted.");
          break;
        default:
          setStatusMessage("Error: " + event.error);
          break;
      }
    };

    return () => {
      recognition.stop();
    };
  }, []);

  useEffect(() => {
    if (transcript.trim() && !processing) {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
      debounceTimerRef.current = setTimeout(() => {
        queryAI(transcript.trim());
        setTranscript('');
        setProcessing(true);
        setStatusMessage('Thinking...');
      }, 2000);
    }
  }, [transcript]);

  useEffect(() => {
    setShowOrbAnimation(processing || audioPlaying);
  }, [processing, audioPlaying]);

  useEffect(() => {
    if (!audioPlaying && hasInteracted) {
      startRecognition();
    }
  }, [audioPlaying, hasInteracted]);

  const queryAI = async (text) => {
    if (processing || audioPlaying || text === lastProcessedTranscript.current || !user) return;
    console.log('queryAI called');

    setProcessing(true);

    try {
        axiosCancelSource.current.cancel();
        axiosCancelSource.current = axios.CancelToken.source();
        lastProcessedTranscript.current = text;

        const response = await axios.get(`https://gm-panda-one.vercel.app/api/generate`, {
            params: {
                text: encodeURIComponent(text),
                providers: encodeURIComponent(modelButtonText),
                persona: encodeURIComponent(personaButtonText),
                session_token: user.session_token
            },
            responseType: 'blob',
            cancelToken: axiosCancelSource.current.token
        });

        console.log("Response data:", response.data);

        // Process audio response
        const audioBuffer = await new Response(response.data).arrayBuffer();
        const audioContext = new AudioContext();
        const gainNode = audioContext.createGain();
        gainNode.gain.value = 1;
        gainNode.connect(audioContext.destination);

        audioContext.decodeAudioData(audioBuffer, (decodedData) => {
            const audioSource = audioContext.createBufferSource();
            audioSource.buffer = decodedData;
            audioSource.connect(gainNode);
            audioSource.start();
            audioSourceRef.current = audioSource;

            setAudioPlaying(true);
            audioSource.onended = async () => {
                setAudioPlaying(false);
                setStatusMessage('Listening...');
                if (!listening && speechRecognitionSupported && !hasStarted) {
                    setTimeout(() => {
                        setHasStarted(true);
                        setListening(true);
                        recognitionRef.current.start();
                    }, 1000);
                }
                
                // Fetch updated credits after audio playback
                await fetchCredits();
            };
        });

    } catch (error) {
        if (!axios.isCancel(error)) {
            console.error("Error in CapxAI response or TTS:", error);
            setStatusMessage('Error processing request');
        }
    } finally {
        setProcessing(false);
        if (!audioPlaying) {
            setTranscript('');
        }
    }
};

  const startRecognition = () => {
    if (isOutOfCredits) {
      // setStatusMessage("You're out of credits. Please purchase more to continue.");
      return;
    }
    if (!processing && speechRecognitionSupported && !listening && hasInteracted && !recognitionRef.current.isStarted) {
      recognitionRef.current.start();
      setTranscript('');
    }
  };

  const handleSelectModel = (model) => {
    setSelectedModel(model);
    setModelButtonText(model);
    setLLMModalOpen(false);
  };

  const handleSelectPersona = (persona) => {
    setSelectedPersona(persona);
    setPersonaButtonText(persona);
    setPersonaModalOpen(false);
  };

  const stopProcessing = () => {
    if (audioPlaying && audioSourceRef.current) {
      audioSourceRef.current.stop();
      setAudioPlaying(false);
    }

    axiosCancelSource.current.cancel('Operation canceled by the user.');

    setProcessing(false);
    setStatusMessage('');
    setApiResponse(null);
    setTranscript('');

    if (recognitionRef.current && recognitionRef.current.isStarted) {
      recognitionRef.current.stop();
      recognitionRef.current.isStarted = false;
      setListening(false);
    }

    setTimeout(() => {
      if (speechRecognitionSupported && hasInteracted && !listening) {
        startRecognition();
      }
    }, 500);
  };

  return (
    <div className="container home-main vh-100 d-flex flex-column">
      <Nav 
        onToggleDropdown={() => setDropdownOpen(!dropdownOpen)} 
        dropdownOpen={dropdownOpen}
        user={user}
        onLogout={logout}
        credits={credits}
      />
      <LottieAnimation 
        showOrbAnimation={showOrbAnimation} 
        listening={listening} 
        defaultOptions={{ 
          loop: true, 
          autoplay: showOrbAnimation, 
          animationData, 
          rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
        }} 
      />
      <TranscriptInput 
        speechRecognitionSupported={speechRecognitionSupported} 
        transcript={transcript} 
        interimTranscript={interimTranscript} 
        processing={processing} 
      />
      {statusMessage && !audioPlaying && <StatusMessage message={statusMessage} />}
      {audioPlaying && <StatusMessage message="Speaking..." />}
      {isOutOfCredits && (
        <div className="alert credits-message text-center mt-3">
          You're out of credits. Your credits will reset tomorrow.
        </div>
      )}
      <Controls
        recognitionRef={recognitionRef}
        onOpenModel={() => setLLMModalOpen(true)}
        onOpenPersonaModal={() => setPersonaModalOpen(true)}
        startRecognition={startRecognition}
        stopProcessing={stopProcessing}
        modelButtonText={modelButtonText}
        personaButtonText={personaButtonText}
        isProcessing={processing || audioPlaying}
        hasInteracted={hasInteracted}
        setHasInteracted={setHasInteracted}
        setListening={setListening}
        setProcessing={setProcessing}
        listening={listening}
        audioPlaying={audioPlaying}
        audioSourceRef={audioSourceRef}
        setAudioPlaying={setAudioPlaying}
        disabled={isOutOfCredits}
      />
      <LLMSelectionModal
        isOpen={isLLMModalOpen}
        onClose={() => setLLMModalOpen(false)}
        onSelectModel={handleSelectModel}
        selectedModel={selectedModel}
      />
      <PersonaSelectionModal
        isOpen={isPersonaModalOpen}
        onClose={closePersonaModal}
        onSelectPersona={handleSelectPersona}
        selectedPersona={selectedPersona}
      />
      <Footer />
    </div>
  );
}

export default Home;