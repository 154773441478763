import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import axios from 'axios';

const TelegramEntry = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleTelegramAuth = async () => {
      if (window.Telegram && window.Telegram.WebApp) {
        const initData = window.Telegram.WebApp.initData;
        console.log('Telegram WebApp initData:', initData);
        if (initData) {
          try {
            console.log('Sending initData to server:', initData);
            const response = await axios.post('https://gm-panda-one.vercel.app/api/tg-auth', { initData });
            console.log('Telegram WebApp authentication response:', response.data);
            if (response.data && response.data.id) {
              login(response.data);
              navigate('/');
            } else {
              throw new Error('Invalid user data received from server');
            }
          } catch (error) {
            console.error('Telegram WebApp authentication failed:', error);
            let errorMessage = 'Authentication failed. Please try again.';
            if (error.response) {
              console.error('Error response:', error.response.data);
              errorMessage = error.response.data.error || errorMessage;
            }
            setError(errorMessage);
            // Optionally, you can navigate to login after a delay
            setTimeout(() => navigate('/login'), 5000);
          }
        } else {
          console.log('No initData found, redirecting to login');
          navigate('/login');
        }
      } else {
        console.log('Telegram WebApp not detected, redirecting to login');
        navigate('/login');
      }
    };

    handleTelegramAuth();
  }, [login, navigate]);

  if (error) {
    return <div>Error: {error}. Redirecting to login in 5 seconds...</div>;
  }

  return;
};

export default TelegramEntry;